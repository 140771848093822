import React, { useState, useEffect, useRef, useCallback } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import {
  useGLTF,
  ContactShadows,
  Environment,
  OrbitControls,
} from "@react-three/drei";
import ObjectElements from "./ObjectElements";

export interface dataItemBD {
  id: number;
  codes: string;
  field: string;
  cat: string;
  comment?: string;
}

export interface dataItem {
  id: number;
  codes: string;
  field: string;
  cat: string;
  comment?: string;
  imageSrc?: string;
}

interface SceneProps {
  data: dataItemBD[];
  isHaveFilter: boolean;
  url: string;
  current: string[];
  setCurrent: (value: string[]) => void;
  hover: string[];
  setHover: (value: string[]) => void;
  tooltip: {
    name: string;
    visible: boolean;
    codes: string;
    cat: string;
  } | null;
  setTooltip: (
    value: { name: string; visible: boolean; codes: string; cat: string } | null
  ) => void;
  onElementClick: (name: string) => void;
  cart: dataItem[];
  setCart: (value: React.SetStateAction<dataItem[]>) => void;
  cartVisible: boolean;
  setCartVisible: (value: boolean) => void;
}

const saveComment = (elementId: string, comment: string) => {
  const comments = JSON.parse(localStorage.getItem("comments") || "{}");
  comments[elementId] = comment;
  localStorage.setItem("comments", JSON.stringify(comments));
};

const loadComment = (elementId: string): string => {
  const comments = JSON.parse(localStorage.getItem("comments") || "{}");
  return comments[elementId] || "";
};

const saveCart = (cart: dataItem[]) => {
  localStorage.setItem("cart", JSON.stringify(cart));
};

const loadCart = (): dataItem[] => {
  const savedCart = localStorage.getItem("cart");
  return savedCart ? JSON.parse(savedCart) : [];
};

const Scene: React.FC<SceneProps> = ({
  data,
  url,
  current,
  setCurrent,
  hover,
  setHover,
  tooltip,
  setTooltip,
  onElementClick,
  cart,
  setCart,
  cartVisible,
  setCartVisible,
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<{
    imageSrc: string;
    codes: string;
    cat: string;
  }>({ imageSrc: "", codes: "", cat: "" });
  const [comment, setComment] = useState<string>("");
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [makeScreenshot, setMakeScreenshot] = useState<boolean>(false);
  const [isInfoOpen, setIsInfoOpen] = useState<boolean>(false);
  const glRef = useRef<any>(null);
  const sceneRef = useRef<THREE.Scene | null>(null);
  const cameraRef = useRef<THREE.Camera | null>(null);

  useEffect(() => {
    if (tooltip && tooltip.name) {
      setComment(loadComment(tooltip.name));
    }
  }, [tooltip]);

  useEffect(() => {
    const savedCart = loadCart();
    setCart(savedCart);
  }, [setCart]);

  useEffect(() => {
    const savedComment = localStorage.getItem("currentComment");
    if (savedComment) {
      setComment(savedComment);
    }
  }, []);

  const handleSendForRevision = () => {
    const newItem = {
      id: Date.now(),
      codes: modalData.codes,
      field: modalData.codes,
      cat: modalData.cat,
      comment: comment,
      imageSrc: modalData.imageSrc,
    };
    saveComment(modalData.codes, comment);
    localStorage.setItem(`screenshot-${newItem.field}`, newItem.imageSrc || "");
    setCart((prevCart) => {
      const updatedCart = [...prevCart, newItem];
      saveCart(updatedCart);
      return updatedCart;
    });
    setModalOpen(false);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalData({ imageSrc: "", codes: "", cat: "" });
  };

  const handleScreenshot = useCallback(() => {
    if (glRef.current && sceneRef.current && cameraRef.current) {
      const renderer = glRef.current;
      renderer.render(sceneRef.current, cameraRef.current);
      const imageSrc = renderer.domElement.toDataURL("image/png");
      setModalData({
        imageSrc,
        codes: tooltip?.codes || "",
        cat: tooltip?.cat || "",
      });
      setModalOpen(true);
    }
  }, [tooltip]);

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
    if (tooltip && tooltip.name) {
      saveComment(tooltip.name, e.target.value);
    }
  };

  const handleSendCart = async () => {
    const cartData = cart.map((item) => ({
      code: item.codes,
      name: item.cat,
      image: item.imageSrc,
      comment: item.comment,
    }));

    const response = await fetch("/send-cart.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(cartData),
    });

    if (response.ok) {
      alert("Данные отправлены на почту");
    } else {
      alert("Ошибка при отправке данных");
    }
  };

  const CaptureRenderer = () => {
    const { gl, scene, camera } = useThree();
    useEffect(() => {
      glRef.current = gl;
      sceneRef.current = scene;
      cameraRef.current = camera;
    }, [gl, scene, camera]);
    return null;
  };

  const obj: any = useGLTF(url);
  const { nodes, materials } = obj;

  return (
    <>
      <div className="box-canvas__content">
        <div className="box-canvas">
          <Canvas
            shadows
            style={{ height: "100%", width: "100%", minHeight: 500 }}
            orthographic
            camera={{
              zoom: 2500,
              near: -1000,
              far: 1000,
              position: [10, 0, 5],
            }}
          >
            <CaptureRenderer />
            <ambientLight intensity={0.7} />
            <spotLight
              intensity={0.5}
              angle={0.1}
              penumbra={1}
              position={[10, 15, 10]}
              castShadow
            />
            <ObjectElements
              nodes={nodes}
              materials={materials}
              hover={hover}
              setHover={setHover}
              current={current}
              setCurrent={setCurrent}
              data={data}
              tooltip={tooltip}
              setTooltip={setTooltip}
              onElementClick={onElementClick}
              setModalOpen={setModalOpen}
              setModalData={setModalData}
              setShowInfo={setShowInfo}
              setMakeScreenshot={setMakeScreenshot}
              isInfoOpen={isInfoOpen}
              setIsInfoOpen={setIsInfoOpen}
            />
            <Environment preset="city" />
            <ContactShadows
              position={[0, 0.8, 0]}
              opacity={0.25}
              scale={10}
              blur={1.5}
              far={0.8}
            />
            <OrbitControls makeDefault enableZoom={true} />
          </Canvas>
        </div>
      </div>
      {showInfo && isInfoOpen && tooltip && tooltip.visible && (
        <div
          style={{
            position: "absolute",
            top: "50px",
            left: "10px",
            background: "white",
            padding: "10px",
            border: "1px solid black",
            borderRadius: "5px",
            zIndex: 1000,
            fontSize: "14px",
          }}
        >
          <p>
            <strong>Коды:</strong>
            {tooltip.codes.split("|").map((code, index) => (
              <div key={index}>{code}</div>
            ))}
          </p>
          <p>
            <strong>Наименования:</strong>
            {tooltip.cat.split("|").map((cat, index) => (
              <div key={index}>{cat}</div>
            ))}
          </p>
          <button className="btn__3d btn__error" onClick={handleScreenshot}>
            Сообщить о несоответствии
          </button>
        </div>
      )}
      {modalOpen && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "rgba(0, 0, 0, 0.8)",
            padding: "20px",
            border: "1px solid black",
            borderRadius: "5px",
            zIndex: 1000,
            width: "80%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1, marginRight: "20px" }}>
            <p style={{ color: "white" }}>
              <strong>Код:</strong>
              {modalData.codes.split("|").map((code, index) => (
                <div key={index}>{code}</div>
              ))}
            </p>
            <p style={{ color: "white" }}>
              <strong>Наименование:</strong>
              {modalData.cat.split("|").map((cat, index) => (
                <div key={index}>{cat}</div>
              ))}
            </p>
            <textarea
              value={comment}
              onChange={handleCommentChange}
              placeholder="Введите комментарий"
              style={{ width: "100%", height: "100px", marginBottom: "10px" }}
            />
            <div className="box__btn">
              <button
                className="btn__3d"
                onClick={handleSendForRevision}
              >
                Отправить
              </button>
              <button
                className="btn__3d"
                onClick={closeModal}
              >
                Закрыть
              </button>
            </div>
          </div>
          <div style={{ flex: 1 }}>
            {modalData.imageSrc && (
              <img
                className="imgCart"
                src={modalData.imageSrc}
                alt="Полноразмерный скриншот"
                style={{
                  height: "400px",
                  marginBottom: "20px",
                  maxWidth: "100%",
                }}
              />
            )}
          </div>
        </div>
      )}
      <div style={{ position: "absolute", top: "50px", right: "10px" }}>
        <button
          className="btn__3d btn__cart"
          onClick={() => setCartVisible(true)}
        >
          Корзина ({cart.length})
        </button>
      </div>
      {cartVisible && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "white",
            padding: "20px",
            border: "1px solid black",
            zIndex: 1000,
            width: "80%",
            height: "600px",
            overflowX: "auto",
          }}
        >
          <h3>Несоответствия</h3>
          {cart.map((item: dataItem) => (
            <div
              key={item.id}
              style={{
                marginBottom: "20px",
                position: "relative",
                borderBottom: "1px solid #ccc",
                padding: "20px",
              }}
            >
              <button
                className="btn__close"
                onClick={() => {
                  const updatedCart = cart.filter(
                    (cartItem) => cartItem.id !== item.id
                  );
                  setCart(updatedCart);
                  saveCart(updatedCart);
                }}
                style={{
                  position: "absolute",
                  top: "-5px",
                  right: "-5px",
                  background: "none",
                  border: "none",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              >
                ×
              </button>
              <table className="ikswebCart">
                <thead>
                  <tr>
                    <th>Код</th>
                    <th>Наименование</th>
                    <th>Изображение</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {item.codes.split("|").map((code, index) => (
                        <div key={index}>{code}</div>
                      ))}
                    </td>
                    <td>
                      {item.cat.split("|").map((cat, index) => (
                        <div key={index}>{cat}</div>
                      ))}
                    </td>
                    <td>
                      <img
                        className="imgCart"
                        src={item.imageSrc || ""}
                        alt="Скриншот"
                        height="250"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>Комментарий: {item.comment}</p>
            </div>
          ))}
          <div className="btn__box-cart">
            <button
              className="btn__3d"
              onClick={handleSendCart}
              style={{ padding: "10px 20px", fontSize: "16px" }}
            >
              Отправить на рассмотрение
            </button>
            <button
              className="btn__3d"
              onClick={() => setCartVisible(false)}
              style={{ padding: "10px 20px", fontSize: "16px" }}
            >
              Закрыть
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Scene;
