import React, { useState } from 'react';
import Scene, { dataItem } from "./components/Scene";  // Импортируем интерфейс dataItem
import "./App.css";

const data = [
  {
    id: 1,
    codes: "РВЗ01266482",
    field: "Болт_DIN_933_М4х16",
    cat: "Болт DIN 933-М4х16-5.8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01266535",
    field: "Болт_DIN_933_М5х20",
    cat: "Болт DIN 933-М5х20-5.8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01266535",
    field: "Болт_DIN_933_М5х20_001",
    cat: "Болт DIN 933-М5х20-5.8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01266535",
    field: "Болт_DIN_933_М5х20_002",
    cat: "Болт DIN 933-М5х20-5.8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01266535",
    field: "Болт_DIN_933_М5х20_003",
    cat: "Болт DIN 933-М5х20-5.8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01266548",
    field: "Болт DIN 933 М6х16",
    cat: "Болт DIN 933-М6х16-5.8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01266548",
    field: "Болт DIN 933 М6х16_001",
    cat: "Болт DIN 933-М6х16-5.8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01266548",
    field: "Болт DIN 933 М6х16_002",
    cat: "Болт DIN 933-М6х16-5.8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01266548",
    field: "Болт DIN 933 М6х16_003",
    cat: "Болт DIN 933-М6х16-5.8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01266549",
    field: "Болт_DIN_933_М6х20",
    cat: "Болт DIN 933-М6х20-5.8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01266549",
    field: "Болт_DIN_933_М6х20_001",
    cat: "Болт DIN 933-М6х20-5.8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01266549",
    field: "Болт_DIN_933_М6х20_002",
    cat: "Болт DIN 933-М6х20-5.8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01266549",
    field: "Болт_DIN_933_М6х20_003",
    cat: "Болт DIN 933-М6х20-5.8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01266549",
    field: "Болт_DIN_933_М6х20_004",
    cat: "Болт DIN 933-М6х20-5.8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01266549",
    field: "Болт_DIN_933_М6х20_005",
    cat: "Болт DIN 933-М6х20-5.8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01266549",
    field: "Болт_DIN_933_М6х20_006",
    cat: "Болт DIN 933-М6х20-5.8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01266549",
    field: "Болт_DIN_933_М6х20_007",
    cat: "Болт DIN 933-М6х20-5.8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01266582",
    field: "Болт_DIN_933_М8х20",
    cat: "Болт DIN 933-М8х20-5.8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01266582",
    field: "Болт_DIN_933_М8х20_001",
    cat: "Болт DIN 933-М8х20-5.8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01266582",
    field: "Болт_DIN_933_М8х20_002",
    cat: "Болт DIN 933-М8х20-5.8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01266582",
    field: "Болт_DIN_933_М8х20_003",
    cat: "Болт DIN 933-М8х20-5.8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00025227",
    field: "Винт_барашковый_6х16",
    cat: "Винт барашковый 6х16 оцинк.",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00025227",
    field: "Винт_барашковый_6х16_001",
    cat: "Винт барашковый 6х16 оцинк.",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00025227",
    field: "Винт_барашковый_6х16_002",
    cat: "Винт барашковый 6х16 оцинк.",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00025227",
    field: "Винт_барашковый_6х16_003",
    cat: "Винт барашковый 6х16 оцинк.",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00025227",
    field: "Винт_барашковый_6х16_004",
    cat: "Винт барашковый 6х16 оцинк.",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00025227",
    field: "Винт_барашковый_6х16_005",
    cat: "Винт барашковый 6х16 оцинк.",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00025227",
    field: "Винт_барашковый_6х16_006",
    cat: "Винт барашковый 6х16 оцинк.",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00025227",
    field: "Винт_барашковый_6х16_007",
    cat: "Винт барашковый 6х16 оцинк.",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00025227",
    field: "Винт_барашковый_6х16_008",
    cat: "Винт барашковый 6х16 оцинк.",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00025227",
    field: "Винт_барашковый_6х16_009",
    cat: "Винт барашковый 6х16 оцинк.",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00025227",
    field: "Винт_барашковый_6х16_010",
    cat: "Винт барашковый 6х16 оцинк.",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00166285",
    field: "Втулка_предохранительная",
    cat: "Втулка резиновая предохранительная 10-8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01291976",
    field: "Гайка_DIN_934_М5_8",
    cat: "Гайка DIN 934-М5-8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01291976",
    field: "Гайка_DIN_934_М5_8_001",
    cat: "Гайка DIN 934-М5-8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01291976",
    field: "Гайка_DIN_934_М5_8_002",
    cat: "Гайка DIN 934-М5-8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01291976",
    field: "Гайка_DIN_934_М5_8_003",
    cat: "Гайка DIN 934-М5-8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01291849",
    field: "Гайка_DIN_934_М6_8",
    cat: "Гайка DIN 934-М6-8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01291849",
    field: "Гайка_DIN_934_М6_8_001",
    cat: "Гайка DIN 934-М6-8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01291849",
    field: "Гайка_DIN_934_М6_8_002",
    cat: "Гайка DIN 934-М6-8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01291849",
    field: "Гайка_DIN_934_М6_8_003",
    cat: "Гайка DIN 934-М6-8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01291849",
    field: "Гайка_DIN_934_М6_8_004",
    cat: "Гайка DIN 934-М6-8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01291849",
    field: "Гайка_DIN_934_М6_8_005",
    cat: "Гайка DIN 934-М6-8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01291849",
    field: "Гайка_DIN_934_М6_8_006",
    cat: "Гайка DIN 934-М6-8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01291849",
    field: "Гайка_DIN_934_М6_8_007",
    cat: "Гайка DIN 934-М6-8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01291849",
    field: "Гайка_DIN_934_М6_8_008",
    cat: "Гайка DIN 934-М6-8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01291849",
    field: "Гайка_DIN_934_М6_8_009",
    cat: "Гайка DIN 934-М6-8",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00977790",
    field: "Дверь_верхняя",
    cat: "В050.11.00.004 Дверь (гибка)",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00345653",
    field: "Двигатель_АИР",
    cat: "Эл.двиг:0,25*3000 1081(лапы)56В2Y2 220/380 50 Гц IP55",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00454560",
    field: "Заглушка_под_отверстие_d16",
    cat: "Заглушка пластиковая круглая под отверстие d16, черная",
    comment: ""
  },
  {
    id: 1,
    codes: "РВ000031764",
    field: "Заклепка_М6_СТ_ЦБН_с_насечкой",
    cat: "Заклепка М6 СТ-ЦБН с насечкой",
    comment: ""
  },
  {
    id: 1,
    codes: "РВ000031764",
    field: "Заклепка_М6_СТ_ЦБН_с_насечкой_001",
    cat: "Заклепка М6 СТ-ЦБН с насечкой",
    comment: ""
  },
  {
    id: 1,
    codes: "РВ000031764",
    field: "Заклепка_М6_СТ_ЦБН_с_насечкой_002",
    cat: "Заклепка М6 СТ-ЦБН с насечкой",
    comment: ""
  },
  {
    id: 1,
    codes: "РВ000031764",
    field: "Заклепка_М6_СТ_ЦБН_с_насечкой_003",
    cat: "Заклепка М6 СТ-ЦБН с насечкой",
    comment: ""
  },
  {
    id: 1,
    codes: "РВ000031764",
    field: "Заклепка_М6_СТ_ЦБН_с_насечкой_004",
    cat: "Заклепка М6 СТ-ЦБН с насечкой",
    comment: ""
  },
  {
    id: 1,
    codes: "РВ000031764",
    field: "Заклепка_М6_СТ_ЦБН_с_насечкой_005",
    cat: "Заклепка М6 СТ-ЦБН с насечкой",
    comment: ""
  },
  {
    id: 1,
    codes: "РВ000031764",
    field: "Заклепка_М6_СТ_ЦБН_с_насечкой_006",
    cat: "Заклепка М6 СТ-ЦБН с насечкой",
    comment: ""
  },
  {
    id: 1,
    codes: "РВ000031764",
    field: "Заклепка_М6_СТ_ЦБН_с_насечкой_007",
    cat: "Заклепка М6 СТ-ЦБН с насечкой",
    comment: ""
  },
  {
    id: 1,
    codes: "РВ000031764",
    field: "Заклепка_М6_СТ_ЦБН_с_насечкой_008",
    cat: "Заклепка М6 СТ-ЦБН с насечкой",
    comment: ""
  },
  {
    id: 1,
    codes: "РВ000031764",
    field: "Заклепка_М6_СТ_ЦБН_с_насечкой_009",
    cat: "Заклепка М6 СТ-ЦБН с насечкой",
    comment: ""
  },
  {
    id: 1,
    codes: "РВ000031764",
    field: "Заклепка_М6_СТ_ЦБН_с_насечкой_010",
    cat: "Заклепка М6 СТ-ЦБН с насечкой",
    comment: ""
  },
  {
    id: 1,
    codes: "РВ000031764",
    field: "Заклепка_М6_СТ_ЦБН_с_насечкой_011",
    cat: "Заклепка М6 СТ-ЦБН с насечкой",
    comment: ""
  },
  {
    id: 1,
    codes: "РВ000031764",
    field: "Заклепка_М6_СТ_ЦБН_с_насечкой_012",
    cat: "Заклепка М6 СТ-ЦБН с насечкой",
    comment: ""
  },
  {
    id: 1,
    codes: "РВ000050445",
    field: "Заклепка_М8_СТ_ЦБН_с_насечкой",
    cat: "Заклепка М8 СТ-ЦБН с насечкой",
    comment: ""
  },
  {
    id: 1,
    codes: "РВ000050445",
    field: "Заклепка_М8_СТ_ЦБН_с_насечкой_001",
    cat: "Заклепка М8 СТ-ЦБН с насечкой",
    comment: ""
  },
  {
    id: 1,
    codes: "РВ000050445",
    field: "Заклепка_М8_СТ_ЦБН_с_насечкой_002",
    cat: "Заклепка М8 СТ-ЦБН с насечкой",
    comment: ""
  },
  {
    id: 1,
    codes: "РВ000050445",
    field: "Заклепка_М8_СТ_ЦБН_с_насечкой_003",
    cat: "Заклепка М8 СТ-ЦБН с насечкой",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_001",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_002",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_003",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_004",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_005",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_006",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_007",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_008",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_009",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_010",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_011",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_012",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_013",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_014",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_015",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_016",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_017",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_018",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_019",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_020",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_021",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_022",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_023",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_024",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_025",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_026",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_027",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_028",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_029",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_030",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_031",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_032",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_033",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_034",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_035",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_036",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_037",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_038",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_039",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_040",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_041",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_042",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_043",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_044",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_045",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_046",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_047",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_048",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_049",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_050",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_051",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_052",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_053",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_054",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_055",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_056",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_057",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_058",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_059",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_060",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_061",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_062",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_063",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00081856",
    field: "Заклепка_вытяжная_064",
    cat: "Заклепка вытяжная 4,0х8 сталь",
    comment: ""
  },
  {
    id: 1,
    codes: "РВ000085743",
    field: "Клеммная_коробка",
    cat: "Коробка разветвительная 100х100х55",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00956315",
    field: "Конфузор_РЦ",
    cat: "А000.00.00.005-19 Конфузор РЦ (сверление)",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00956354",
    field: "Крыльчатка_РЦ",
    cat: "А000.00.24.000-34СБ Крыльчатка РЦ RAL9006",
    comment: ""
  },
  {
    id: 1,
    codes: "РВ000019785",
    field: "Наклейка_20",
    cat: "Символ заземления d20мм",
    comment: ""
  },
  {
    id: 1,
    codes: "РВ000019785",
    field: "Наклейка_20_001",
    cat: "Символ заземления d20мм",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00977964",
    field: "Несущая_опора_левая",
    cat: "В050.11.01.005 Несущая опора (гибка)",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00978002",
    field: "Несущая_опора_правая",
    cat: "В050.11.01.006 Несущая опора (гибка)",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00977924",
    field: "Основание_конфузора",
    cat: "В050.11.01.004 Основание конфузора (гибка)",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00977706",
    field: "Панель_корпуса_задняя",
    cat: "В050.11.00.001 Панель корпуса (гибка)",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00977744",
    field: "Панель_корпуса_передняя",
    cat: "В050.11.00.002 Панель корпуса (гибка)",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00978043",
    field: "Площадка_ЭД",
    cat: "В050.11.01.007 Площадка ЭД (гибка)",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00977772",
    field: "Прижим",
    cat: "В050.11.00.003 Прижим (гибка)",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00977808",
    field: "Ревизионное_окно",
    cat: "В050.11.00.005 Ревизионное окно (гибка)",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00977808",
    field: "Ревизионное_окно_001",
    cat: "В050.11.00.005 Ревизионное окно (гибка)",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01175111",
    field: "Скоба_однолапковая_10-11",
    cat: "Скоба металлическая однолапковая d = 10-11 мм",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01125292",
    field: "Ступица",
    cat: "А000.00.00.013 Ступица (протяжка)",
    comment: ""
  },
  {
    id: 1,
    codes: "Р-019289",
    field: "Уголок_20",
    cat: "Уголок №20/95",
    comment: ""
  },
  {
    id: 1,
    codes: "Р-019289",
    field: "Уголок_20_001",
    cat: "Уголок №20/95",
    comment: ""
  },
  {
    id: 1,
    codes: "Р-019289",
    field: "Уголок_20_002",
    cat: "Уголок №20/95",
    comment: ""
  },
  {
    id: 1,
    codes: "Р-019289",
    field: "Уголок_20_003",
    cat: "Уголок №20/95",
    comment: ""
  },
  {
    id: 1,
    codes: "Р-019289",
    field: "Уголок_20_004",
    cat: "Уголок №20/95",
    comment: ""
  },
  {
    id: 1,
    codes: "Р-019289",
    field: "Уголок_20_005",
    cat: "Уголок №20/95",
    comment: ""
  },
  {
    id: 1,
    codes: "Р-019289",
    field: "Уголок_20_006",
    cat: "Уголок №20/95",
    comment: ""
  },
  {
    id: 1,
    codes: "Р-019289",
    field: "Уголок_20_007",
    cat: "Уголок №20/95",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00978062",
    field: "Усиление_площадки",
    cat: "В050.11.01.008 Усиление площадки",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00978062",
    field: "Усиление_площадки_001",
    cat: "В050.11.01.008 Усиление площадки",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00978062",
    field: "Усиление_площадки_002",
    cat: "В050.11.01.008 Усиление площадки",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00978062",
    field: "Усиление_площадки_003",
    cat: "В050.11.01.008 Усиление площадки",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_001",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_002",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_003",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_004",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_005",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_006",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_007",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_008",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_009",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_010",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_011",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_012",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_013",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_014",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_015",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_016",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_017",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_018",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_019",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_020",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_021",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_022",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331121",
    field: "Шайба_простая_4_DIN_125_023",
    cat: "Шайба простая 4 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331127",
    field: "Шайба_простая_6_DIN_125",
    cat: "Шайба простая 6 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331127",
    field: "Шайба_простая_6_DIN_125_001",
    cat: "Шайба простая 6 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331127",
    field: "Шайба_простая_6_DIN_125_002",
    cat: "Шайба простая 6 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331127",
    field: "Шайба_простая_6_DIN_125_003",
    cat: "Шайба простая 6 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331127",
    field: "Шайба_простая_6_DIN_125_004",
    cat: "Шайба простая 6 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331127",
    field: "Шайба_простая_6_DIN_125_005",
    cat: "Шайба простая 6 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331127",
    field: "Шайба_простая_6_DIN_125_006",
    cat: "Шайба простая 6 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331127",
    field: "Шайба_простая_6_DIN_125_007",
    cat: "Шайба простая 6 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331127",
    field: "Шайба_простая_6_DIN_125_008",
    cat: "Шайба простая 6 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331127",
    field: "Шайба_простая_6_DIN_125_009",
    cat: "Шайба простая 6 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331127",
    field: "Шайба_простая_6_DIN_125_010",
    cat: "Шайба простая 6 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331127",
    field: "Шайба_простая_6_DIN_125_011",
    cat: "Шайба простая 6 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331127",
    field: "Шайба_простая_6_DIN_125_012",
    cat: "Шайба простая 6 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331127",
    field: "Шайба_простая_6_DIN_125_013",
    cat: "Шайба простая 6 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331127",
    field: "Шайба_простая_6_DIN_125_014",
    cat: "Шайба простая 6 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331127",
    field: "Шайба_простая_6_DIN_125_015",
    cat: "Шайба простая 6 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331127",
    field: "Шайба_простая_6_DIN_125_016",
    cat: "Шайба простая 6 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331127",
    field: "Шайба_простая_6_DIN_125_017",
    cat: "Шайба простая 6 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331127",
    field: "Шайба_простая_6_DIN_125_018",
    cat: "Шайба простая 6 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331127",
    field: "Шайба_простая_6_DIN_125_019",
    cat: "Шайба простая 6 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331127",
    field: "Шайба_простая_6_DIN_125_020",
    cat: "Шайба простая 6 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331127",
    field: "Шайба_простая_6_DIN_125_021",
    cat: "Шайба простая 6 DIN 125",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331145",
    field: "Шайба_пружинная_5_DIN_127",
    cat: "Шайба пружинная 5 DIN 127",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331145",
    field: "Шайба_пружинная_5_DIN_127_001",
    cat: "Шайба пружинная 5 DIN 127",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331145",
    field: "Шайба_пружинная_5_DIN_127_002",
    cat: "Шайба пружинная 5 DIN 127",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331145",
    field: "Шайба_пружинная_5_DIN_127_003",
    cat: "Шайба пружинная 5 DIN 127",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331170",
    field: "Шайба_пружинная_6_DIN_127",
    cat: "Шайба пружинная 6 DIN 127",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331170",
    field: "Шайба_пружинная_6_DIN_127_001",
    cat: "Шайба пружинная 6 DIN 127",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331170",
    field: "Шайба_пружинная_6_DIN_127_002",
    cat: "Шайба пружинная 6 DIN 127",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331170",
    field: "Шайба_пружинная_6_DIN_127_003",
    cat: "Шайба пружинная 6 DIN 127",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331170",
    field: "Шайба_пружинная_6_DIN_127_004",
    cat: "Шайба пружинная 6 DIN 127",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331170",
    field: "Шайба_пружинная_6_DIN_127_005",
    cat: "Шайба пружинная 6 DIN 127",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331170",
    field: "Шайба_пружинная_6_DIN_127_006",
    cat: "Шайба пружинная 6 DIN 127",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331170",
    field: "Шайба_пружинная_6_DIN_127_007",
    cat: "Шайба пружинная 6 DIN 127",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331170",
    field: "Шайба_пружинная_6_DIN_127_008",
    cat: "Шайба пружинная 6 DIN 127",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331170",
    field: "Шайба_пружинная_6_DIN_127_009",
    cat: "Шайба пружинная 6 DIN 127",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331170",
    field: "Шайба_пружинная_6_DIN_127_010",
    cat: "Шайба пружинная 6 DIN 127",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331170",
    field: "Шайба_пружинная_6_DIN_127_011",
    cat: "Шайба пружинная 6 DIN 127",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331171",
    field: "Шайба_пружинная_8_DIN_127",
    cat: "Шайба пружинная 8 DIN 127",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331171",
    field: "Шайба_пружинная_8_DIN_127_001",
    cat: "Шайба пружинная 8 DIN 127",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331171",
    field: "Шайба_пружинная_8_DIN_127_002",
    cat: "Шайба пружинная 8 DIN 127",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331171",
    field: "Шайба_пружинная_8_DIN_127_003",
    cat: "Шайба пружинная 8 DIN 127",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331162",
    field: "Шайба_усиленная_4_DIN_9021",
    cat: "Шайба усиленная 4 DIN 9021",
    comment: ""
  },
  
  {
    id: 1,
    codes: "РВЗ01331163",
    field: "Шайба_усиленная_5_DIN_9021",
    cat: "Шайба усиленная 5 DIN 9021",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331163",
    field: "Шайба_усиленная_5_DIN_9021_001",
    cat: "Шайба усиленная 5 DIN 9021",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331163",
    field: "Шайба_усиленная_5_DIN_9021_002",
    cat: "Шайба усиленная 5 DIN 9021",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331163",
    field: "Шайба_усиленная_5_DIN_9021_003",
    cat: "Шайба усиленная 5 DIN 9021",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331163",
    field: "Шайба_усиленная_5_DIN_9021_004",
    cat: "Шайба усиленная 5 DIN 9021",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331163",
    field: "Шайба_усиленная_5_DIN_9021_005",
    cat: "Шайба усиленная 5 DIN 9021",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331163",
    field: "Шайба_усиленная_5_DIN_9021_006",
    cat: "Шайба усиленная 5 DIN 9021",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331163",
    field: "Шайба_усиленная_5_DIN_9021_007",
    cat: "Шайба усиленная 5 DIN 9021",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331165",
    field: "Шайба_усиленная_8_DIN_9021",
    cat: "Шайба усиленная 8 DIN 9021",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331165",
    field: "Шайба_усиленная_8_DIN_9021_001",
    cat: "Шайба усиленная 8 DIN 9021",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331165",
    field: "Шайба_усиленная_8_DIN_9021_002",
    cat: "Шайба усиленная 8 DIN 9021",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ01331165",
    field: "Шайба_усиленная_8_DIN_9021_003",
    cat: "Шайба усиленная 8 DIN 9021",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00032691",
    field: "Шина_20",
    cat: "Шина 20 (3) L400/-28",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00032691",
    field: "Шина_20_001",
    cat: "Шина 20 (3) L400/-28",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00032691",
    field: "Шина_20_002",
    cat: "Шина 20 (3) L400/-28",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00032691",
    field: "Шина_20_003",
    cat: "Шина 20 (3) L400/-28",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00032688",
    field: "Шина_20_004",
    cat: "Шина 20 (3) L200/-28",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00032688",
    field: "Шина_20_005",
    cat: "Шина 20 (3) L200/-28",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00032688",
    field: "Шина_20_006",
    cat: "Шина 20 (3) L200/-28",
    comment: ""
  },
  {
    id: 1,
    codes: "РВЗ00032688",
    field: "Шина_20_007",
    cat: "Шина 20 (3) L200/-28",
    comment: ""
  },
];

export default function App() {
  const [current, setCurrent] = useState<string[]>([]);
  const [hover, setHover] = useState<string[]>([]);
  const [tooltip, setTooltip] = useState<{ name: string; visible: boolean; codes: string; cat: string } | null>(null);
  const [cartVisible, setCartVisible] = useState<boolean>(false);
  const [cart, setCart] = useState<dataItem[]>([]);

  const handleElementClick = (name: string) => {
    const elementData = data.find((it) => it.field === name);
    setTooltip(prevTooltip => {
      if (prevTooltip && prevTooltip.name === name) {
        return { ...prevTooltip, visible: !prevTooltip.visible };
      }
      return { name, visible: true, codes: elementData?.codes || "", cat: elementData?.cat || "" };
    });

    setCurrent(prevCurrent => {
      if (prevCurrent.includes(name)) {
        return prevCurrent.filter(item => item !== name);
      } else {
        return [...prevCurrent, name];
      }
    });
  };

  return (
    <>
      <Scene
        isHaveFilter={true}
        url={"./models/40-20-18.glb"}
        data={data}
        current={current}
        setCurrent={setCurrent}
        hover={hover}
        setHover={setHover}
        tooltip={tooltip}
        setTooltip={setTooltip}
        onElementClick={handleElementClick}
        cart={cart}
        setCart={setCart}
        cartVisible={cartVisible}
        setCartVisible={setCartVisible}
      />
    </>
  );
}