import { useRef, useState } from "react";
import * as THREE from "three";
import { dataItem } from "./Scene";

interface NodesType {
  [key: string]: THREE.Mesh;
}

interface MaterialsType {
  [key: string]: (THREE.MeshStandardMaterial | THREE.MeshPhysicalMaterial) & {
    name: string;
  };
}

interface ObjectProps {
  nodes: NodesType;
  materials: MaterialsType;
  current: string[];
  setCurrent: (value: string[]) => void;
  hover: string[];
  setHover: (value: string[]) => void;
  data: dataItem[];
  tooltip: {
    name: string;
    visible: boolean;
    codes: string;
    cat: string;
  } | null;
  setTooltip: (
    value: { name: string; visible: boolean; codes: string; cat: string } | null
  ) => void;
  onElementClick: (name: string) => void;
  setModalData: (value: {
    imageSrc: string;
    codes: string;
    cat: string;
  }) => void;
  setShowInfo: (value: boolean) => void;
  setMakeScreenshot: (value: boolean) => void;
  setModalOpen: (value: boolean) => void;
  isInfoOpen: boolean;
  setIsInfoOpen: (value: boolean) => void;
}

const ObjectElements: React.FC<ObjectProps> = ({
  nodes,
  materials,
  current,
  setCurrent,
  hover,
  setHover,
  data,
  setTooltip,
  setShowInfo,
  setIsInfoOpen,
}) => {
  const ref = useRef<THREE.Group>(null);
  const originalMaterials = useRef<{ [key: string]: THREE.Material }>({});
  const [transparency, setTransparency] = useState<{ [key: string]: boolean }>(
    {}
  );

  const handleMouseOver = (e: any) => {
    e.stopPropagation();
    setHover([e.object.name]);
  };

  const handleMouseOut = (e: any) => {
    e.stopPropagation();
    setHover([]);
  };

  const handleClick = (e: any) => {
    e.stopPropagation();
    const elementName = e.object.name;
    const elementData = data.find((it) => it.field === elementName);
    const elementCat = elementData?.cat;
    const elementCodes = elementData?.codes;

    if (e.ctrlKey) {
      if (current.includes(elementName)) {
        const newCurrent = current.filter((item) => item !== elementName);
        setCurrent(newCurrent);
        setTransparency((prev) => {
          const newTransparency = { ...prev };
          delete newTransparency[elementName];
          return newTransparency;
        });
        if (newCurrent.length === 0) {
          setTooltip(null);
          setShowInfo(false);
          setIsInfoOpen(false);
        } else {
          const uniqueCats = Array.from(
            new Set(
              newCurrent.map(
                (name) => data.find((it) => it.field === name)?.cat
              )
            )
          );
          const uniqueCodes = Array.from(
            new Set(
              newCurrent.map(
                (name) => data.find((it) => it.field === name)?.codes
              )
            )
          );
          setTooltip({
            name: newCurrent.join(" "),
            visible: true,
            codes: uniqueCodes.join("|"),
            cat: uniqueCats.join("|"),
          });
        }
      } else {
        const newCurrent = [...current, elementName];
        const uniqueCats = Array.from(
          new Set(
            newCurrent.map((name) => data.find((it) => it.field === name)?.cat)
          )
        );
        const uniqueCodes = Array.from(
          new Set(
            newCurrent.map(
              (name) => data.find((it) => it.field === name)?.codes
            )
          )
        );
        setCurrent(newCurrent);
        setTransparency((prev) => ({ ...prev, [elementName]: false }));
        setTooltip({
          name: newCurrent.join(" "),
          visible: true,
          codes: uniqueCodes.join("|"),
          cat: uniqueCats.join("|"),
        });
        setShowInfo(true);
        setIsInfoOpen(true);
      }
    } else {
      if (current.includes(elementName)) {
        setCurrent([]);
        setTooltip(null);
        setShowInfo(false);
        setIsInfoOpen(false);
        setTransparency({});
      } else {
        const currentCats = current.map(
          (item) => data.find((it) => it.field === item)?.cat
        );
        if (currentCats.includes(elementCat)) {
          const newCurrent = [...current, elementName];
          const uniqueCats = Array.from(
            new Set(
              newCurrent.map(
                (name) => data.find((it) => it.field === name)?.cat
              )
            )
          );
          const uniqueCodes = Array.from(
            new Set(
              newCurrent.map(
                (name) => data.find((it) => it.field === name)?.codes
              )
            )
          );
          setCurrent(newCurrent);
          setTooltip({
            name: newCurrent.join(" "),
            visible: true,
            codes: uniqueCodes.join("|"),
            cat: uniqueCats.join("|"),
          });
          setTransparency((prev) => ({ ...prev, [elementName]: false }));
        } else {
          const newTransparency = Object.keys(nodes).reduce((acc, key) => {
            acc[key] = key !== elementName;
            return acc;
          }, {} as { [key: string]: boolean });

          setCurrent([elementName]);
          setTransparency(newTransparency);
          setTooltip({
            name: elementName,
            visible: true,
            codes: elementCodes || "",
            cat: elementCat || "",
          });
          setShowInfo(true);
          setIsInfoOpen(true);
        }
      }
    }
  };
  return (
    <group
      ref={ref}
      onPointerOver={handleMouseOver}
      onPointerOut={handleMouseOut}
      onClick={handleClick}
    >
      {Object.keys(nodes).map((item: string) => {
        let mat;
        const nodeMaterial = nodes[item].material as THREE.Material;
        if (
          nodeMaterial &&
          ("name" in nodeMaterial || Array.isArray(nodeMaterial)) &&
          materials[nodeMaterial.name]
        ) {
          if (!originalMaterials.current[item]) {
            originalMaterials.current[item] =
              materials[nodeMaterial.name].clone();
          }

          mat = originalMaterials.current[item].clone();

          if (current.includes(item)) {
            if ("color" in mat) {
              (mat as THREE.MeshStandardMaterial).color = new THREE.Color(
                "#ff0000"
              );
            }
          } else if (hover.includes(item)) {
            if ("color" in mat) {
              (mat as THREE.MeshStandardMaterial).color = new THREE.Color(
                "#fdd500"
              );
            }
          } else if (transparency[item]) {
            mat.opacity = 0.3;
            mat.transparent = true;
          } else {
            mat.opacity = 1;
            mat.transparent = false;
          }
        }
        return (
          <mesh
            key={item}
            geometry={nodes[item].geometry}
            material={mat}
            name={item}
            scale={0.006}
            position={
              nodes.Scene.children.find((it: any) => it.name === item)
                ?.position || [0, 0, 0]
            }
          />
        );
      })}
    </group>
  );
};

export default ObjectElements;